import React from "react"

import CountUpWrapper from "./countUpWrapper"

import "./counters.scss"

const Counters = ({ counters }) => {
  return (
    <>
      {counters.map((c, i) => (
        <div className="counter" key={i}>
          <div className="counter-title">{c.title}</div>
          <div>
            <CountUpWrapper endValue={c.value} />
            <div className="counter-unit">{c.unit}</div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Counters
