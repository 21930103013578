import React from "react"

const Tooltip = ({ partnerPoint, setPartnerPoints, hideTooltip }) => {
  const { circle, partner } = partnerPoint || {}

  if (!circle || !partner) return null

  return (
    <svg
      x={circle.x}
      y={circle.y - 100}
      onMouseEnter={() => {
        setPartnerPoints(data =>
          data.map(item => ({
            ...item,
            hover:
              item.circle.x === circle.x && item.circle.y === circle.y
                ? true
                : false,
          }))
        )
      }}
      onMouseLeave={hideTooltip}
    >
      <text
        x={12}
        y={104}
        fontFamily="Noto Serif"
        fill="black"
        fontWeight="700"
        fontSize="12"
      >
        {partner.name}
      </text>
    </svg>
  )
}

export default Tooltip
