import React, { useState } from "react"
import { useCountUp } from "react-countup"
import VizSensor from "react-visibility-sensor"

const CountUpWrapper = ({ endValue }) => {
  const [visited, setVisited] = useState(false)
  const { countUp, start } = useCountUp({
    start: 0,
    end: endValue,
    duration: 1,
    separator: " ",
    startOnMount: false,
  })
  const onChange = isVisible => {
    if (isVisible && !visited) {
      setVisited(true)
      start()
    }
  }
  return (
    <VizSensor onChange={onChange} partialVisibility>
      <div className="counter-value">{countUp}</div>
    </VizSensor>
  )
}

export default CountUpWrapper
